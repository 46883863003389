import React from "react"
import currency from "currency.js"
import { useSnackbar } from "notistack"
import Transaction from "../documents/Transaction"
import createStyle from "./snippetTheme"
import loadingAnimation from "../public/loading.gif"
import approvedGif from "../public/approved.gif"
import declinedGif from "../public/declined.gif"
import { createTicket, createTicketEPN, formatDate } from "./functions"
import usePromise from "./usePromise"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { Controller, get, useForm } from "react-hook-form"
import { Email, PhoneIphone, Print } from "@mui/icons-material"
import { EmailRegex, PhoneRegex } from "./regex"
import { PatternFormat } from "react-number-format"
import { IS_EPN, IS_REEF } from "../global"
import { v4 } from "uuid"
import shortUUID from "short-uuid"
import secret from "../server/secret"

export default function useGateway(mid = "", username = "", password = "", key = "", paylinkApiKey = "") {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()

  // States
  const [open, setOpen] = React.useState(false)
  const [resolver, setResolver] = React.useState({ resolver: null })
  const [transaction, setTransaction] = React.useState({ cash: 0, credit: 0, tax: 0, cardAdminFee: 0, cardAdminFeeTax: 0, tip: 0, invoiceNumber: "" })

  // Functions 
  function sale(cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, device, invoiceNumber = "", callback = () => { }) {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          DeviceSerial: device,
          ServiceType: "SALE",
          Amount: credit + tip,
          InvoiceNumber: invoiceNumber,
          TipRequest: false,
          TipAmount: tip,
          TenderType: "CREDIT",
          AutoRun: true,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Sale", async result => {
          const response = await fetch(`/api/GetTransactionResponse/${result.GUID}`, { headers: { "Authorization": `Bearer ${paylinkApiKey}` } })
          const data = await response.json()
          createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, data.PNRef, invoiceNumber)
          callback(data)
          promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, invoiceNumber || data.PNRef, "************" + data.Last4, data.CardType, data.AuthCode)
        }))
    }

    if (IS_EPN) {
      if (device !== "notset") {
        fetch("/api/valor-connect", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amount: currency(credit).intValue.toString(),
            tip: currency(tip).intValue.toString(),
            epi: device
          })
        }).then(response => response.json())
          .then(data => {
            if (data.STATE === "0") {
              createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, data.TXN_ID, invoiceNumber)
              callback()
              promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, invoiceNumber || data.TXN_ID, data.MASKED_PAN, data.ISSUER, data.CODE)
            }
          })
      } else {
        // Generate an invoice number, if necessary
        // This is necessary to track the transaction through valor
        if (!invoiceNumber) invoiceNumber = shortUUID.generate(v4())
        fetch("/api/epn/sale", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            tax: tax,
            username: username,
            password: password,
            key: key,
            amount: credit + tip,
            invoicenumber: invoiceNumber
          })
        }).then(response => response.json())
          .then(data => {
            if (!data.url) return enqueueSnackbar(data.desc, { variant: "error" })
            var w = window.open(data.url, "_blank")
            w.sessionStorage.clear()  // Removes the login state for the new tab
            createTicketEPN(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, invoiceNumber, "", () => {
              promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, invoiceNumber)
              callback()
            })
          })
      }
    }
  }

  function repeatSale(cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, transactionId) {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          ServiceType: "REPEATSALE",
          Amount: credit,
          PNRef: transactionId,
          TipAmount: tip,
          AutoRun: true,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Repeat Sale", result => {
          createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, result.PNRef)
          promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, result.PNRef, "************" + result.Last4, result.CardType, result.AuthCode)
        }))
    }

    if (IS_EPN) {
      fetch("/api/epn/sale", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          tax: tax,
          username: username,
          password: password,
          key: key,
          amount: credit + tip,
          token: transactionId
        })
      }).then(response => response.json())
        .then(data => {
          if (!data.success_url) return
          createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, data.txnid)
          promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, data.txnid, data.pan, data.card_type, data.approval_code)
        })
    }
  }

  function refund(transactionId, amount, reason = "No Reason Specified.") {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          ServiceType: "CREDIT",
          Amount: amount,
          PNRef: transactionId,
          AutoRun: true,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Refund"))
    }

    if (IS_EPN) {
      fetch("/api/epn/refund", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
          key: key,
          transactionID: transactionId,
          amount: amount
        })
      }).then(response => response.json())
        .then(data => {
          if (data.msg) enqueueSnackbar(data.msg, { variant: "success" })
          if (data.mesg) enqueueSnackbar(data.mesg, { variant: "error" })
          console.log(data)
        })
    }
  }

  function reverse(transactionID) {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          ServiceType: "VOID",
          PNRef: transactionID,
          AutoRun: true,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Void"))
    }

    if (IS_EPN) {
      fetch("/api/epn/void", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
          key: key,
          transactionID: transactionID
        })
      }).then(response => response.json())
        .then(data => {
          console.log(data)
          if (data.mesg) return enqueueSnackbar(data.mesg, { variant: "error" })
          if (data.msg) {
            enqueueSnackbar(data.msg, { variant: "success" })
            fetch(`/api/giftcard/void?REF_TXN_ID=${transactionID}`)
            fetch(`/api/ticket/void?REF_TXN_ID=${transactionID}`)
          }
        })
    }
  }

  function adjustTip(transactionId, amount) {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          ServiceType: "ADDTIPNOUI",
          Amount: amount,
          PNRef: transactionId,
          AutoRun: true,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Tip Adjust"))
    }

    if (IS_EPN) {
      fetch("/api/epn/tipadjust", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
          key: key,
          transactionID: transactionId,
          amount: amount
        })
      }).then(response => response.json())
        .then(data => {
          if (data.msg) enqueueSnackbar(data.msg, { variant: "success" })
          if (data.mesg) enqueueSnackbar(data.mesg, { variant: "error" })
          console.log(data)
        })
    }
  }

  function closeBatch() {
    if (IS_REEF) {
      fetch(`/api/transaction`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${paylinkApiKey}`, "Content-Type": "application/json" },
        body: JSON.stringify({
          ServiceType: "CAPTUREALL",
          TenderType: "ALL",
          AutoRun: false,
          AsModal: true
        })
      }).then(response => response.json())
        .then(data => openSnippetWithCSS(data.Snippet, "Close Batch"))
    }

    if (IS_EPN) {
      fetch("/api/epn/settlement", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
          key: key
        })
      }).then(response => response.json())
        .then(data => {
          if (data.msg) enqueueSnackbar(data.msg, { variant: "success" })
          if (data.mesg) enqueueSnackbar(data.mesg, { variant: "error" })
          console.log(data)
        })
    }
  }

  // Not a gateway function anymore. Can be moved later.
  function sendInvoiceViaEmail(sender, email, invoiceId, invoice, subjectPrefix, logo = "") {
    fetch(`/api/email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sender: sender || "reefpay@paradisepos.com",
        recipient: email,
        subject: `${subjectPrefix ? subjectPrefix : "Invoice"}: ${invoice.invoiceNumber}`,  // Prepend subject prefix if provided
        html: `
          <div>
            <img
              src=${logo}
              alt=""
              width="128px"
            />
            <pre style="font-size: 14px;">
Dear ${invoice.customer.firstName},

You have received an invoice!

Please review the invoice before submitting payment.
If you have already paid, please disregard this email.

<b>Invoice Number:</b> ${invoice.invoiceNumber}
<b>Due Date:</b> ${new Date(invoice.dueDate).toLocaleDateString()}
<b>Amount:</b> ${currency(invoice.total, { fromCents: true }).format()}
            </pre>
            <a
              href="${window.location.origin}/pay/${invoiceId}"
              style="
                text-decoration: none;
                border: none;
                border-radius: 5px;
                padding: 10px;
                background-color: #2e7d32;
                color: white;
              "
            >
              View Your Invoice
            </a>
          </div>
        `
      })
    }).then(response => {
      if (response.status === 200) enqueueSnackbar(`Invoice sent to ${email}!`, { variant: "success" })
      else enqueueSnackbar("Something went wrong. Try again later.", { variant: "error" })
    })
  }

  function sendInvoiceViaText(invoiceId, phone) {
    fetch(`/api/sms`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: phone,
        message: `${window.location.origin}/pay/${invoiceId}`
      })
    }).then(response => {
      if (response.status === 200) enqueueSnackbar(`Invoice sent to ${phone}!`, { variant: "success" })
      else enqueueSnackbar("Something went wrong. Try again later.", { variant: "error" })
    })
  }

  // startdate, enddate, settleflag, options
  async function getTransactions(start_date, end_date, nameOnCard, invoiceNumber, settleFlag) {
    try {
      if (IS_REEF) {
        start_date = formatDate(start_date, "mdy")
        end_date = formatDate(end_date, "mdy")
      }

      if (IS_EPN) {
        start_date = formatDate(start_date, "ymd")
        end_date = formatDate(end_date, "ymd")
      }

      const response = await fetch(`/api/get-transactions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          gateway: secret.DATABASE_NAME,
          username: username,
          password: password,
          key: key,
          start_date: start_date,
          end_date: end_date,
          nameOnCard: nameOnCard,
          invoiceId: invoiceNumber,
          settleFlag: settleFlag
        })
      })

      const data = await response.json()

      if (data) {
        function extractTextFromField(field) {
          if (field === undefined) return ""
          if (field._text === undefined) return ""
          return field._text.trim()
        }

        function parseTransaction(transaction) {
          if (IS_REEF) {
            return (
              new Transaction(
                extractTextFromField(transaction.AVS_Resp_CH),
                extractTextFromField(transaction.Street_CH),
                extractTextFromField(transaction.Zip_CH),
                extractTextFromField(transaction.CV_Resp_CH),
                extractTextFromField(transaction.Account_Type_CH),
                extractTextFromField(transaction.Name_on_Card_VC),
                extractTextFromField(transaction.Acct_Num_CH),
                extractTextFromField(transaction.Exp_CH),
                extractTextFromField(transaction.Approval_Code_CH),
                extractTextFromField(transaction.Auth_Amt_MN),
                extractTextFromField(transaction.Auth_Date_DT),
                extractTextFromField(transaction.BatchID_VC),
                extractTextFromField(transaction.Date_DT),
                extractTextFromField(transaction.Exp_CH),
                extractTextFromField(transaction.Invoice_ID),
                extractTextFromField(transaction.Manual),
                extractTextFromField(transaction.Processor_ID),
                extractTextFromField(transaction.Register_Number_CH),
                extractTextFromField(transaction.Result_Txt_VC),
                extractTextFromField(transaction.Reversal_Flag_CH),
                extractTextFromField(transaction.Settle_Date_DT),
                extractTextFromField(transaction.Settle_Flag_CH),
                extractTextFromField(transaction.TRX_HD_Key),
                extractTextFromField(transaction.Tip_Amt_MN),
                extractTextFromField(transaction.Total_Amt_MN),
                extractTextFromField(transaction.Trans_Type_ID),
                extractTextFromField(transaction.Void_Flag_CH),
                extractTextFromField(transaction.Processor_ID),
                extractTextFromField(transaction.TRX_HD_Key)
              )
            )
          }

          if (IS_EPN) {
            return new Transaction(
              "",
              "",
              "",
              "",
              transaction.CARD_SCHEME,
              transaction.CARDHOLDER_NAME,
              transaction.PAN,
              "",
              transaction.APPROVAL_CODE,
              currency(transaction.BASE_AMOUNT, { fromCents: true }).value,
              "",
              transaction.BATCH_NO,
              transaction.TXN_ORIG_DATE,
              "",
              transaction.INVOICE_NO,
              "",
              "",
              "",
              transaction.IS_SETTLED === "0" ? "OPEN" : "CLOSED",
              transaction.IS_REVERSAL,
              "",
              transaction.IS_SETTLED,
              transaction.REF_TXN_ID,
              currency(transaction.TIP_AMOUNT, { fromCents: true }).value,
              currency(transaction.NET_AMOUNT, { fromCents: true }).value,
              transaction.TXN_TYPE,
              transaction.IS_VOID,
              transaction.EPI,
              transaction.TOKEN
            )
          }

          return transaction
        }

        let transactions = data.map(parseTransaction)
        transactions = transactions.filter(transaction => { return transaction.voidFlag === 0 })
        return transactions
      }
    } catch (error) { console.log(error) }
    return []
  }

  function openSnippetWithCSS(snippet, title, callback = () => console.log("No Callback!")) {
    if (snippet === undefined)
      return enqueueSnackbar("Something went wrong!", { variant: "error" })

    // Override snippet animation resources
    // This targets both the img element and the script which updates the img during processing
    snippet = snippet.replaceAll("https://pay.link/images/paylink-wait.gif", loadingAnimation)
    snippet = snippet.replaceAll("https://pay.link/images/paylink-complete.gif", approvedGif)
    snippet = snippet.replaceAll("https://pay.link/images/paylink-failed.gif", declinedGif)

    // Override failed result message to account for the "COMPLETE, BUT NOT COMPLETE" bug.
    // I would like to note... I believe this implementation to be rather unstable and hope Paygistix fixes this issue properly on their end.
    const snippetChunks = snippet.split("document.getElementById('PaygistixResult').innerHTML = eData.Message;")
    const updatedSnippet =
      snippetChunks[0] + "document.getElementById('PaygistixResult').innerHTML = eData.Message;" +
      snippetChunks[1] + "console.log(eData);document.getElementById('PaygistixResult').innerHTML = eData.Message || 'Please try again.';" +
      `\ndocument.getElementById('PaygistixStreamedMessage').innerHTML = "Something Went Wrong";` +
      snippetChunks[2]

    console.log(updatedSnippet)

    // Wrap snippet inside a div for more control
    const div = document.createElement("div")
    div.id = "snippet"
    div.innerHTML = updatedSnippet
    div.callback = callback
    div.appendChild(createStyle())  // Append CSS styles

    // Append a cancel button
    const btn = document.createElement("button")
    btn.id = "cancelbtn"
    btn.innerHTML = "Cancel"
    btn.onclick = () => { document.getElementById('snippet').remove() }
    div.appendChild(btn)

    // Manually generate script tags to force the script to run after insertion
    Array.from(div.querySelectorAll("script")).forEach(oldScript => {
      const newScript = document.createElement("script")
      Array.from(oldScript.attributes).forEach(attribute => newScript.setAttribute(attribute.name, attribute.value))
      const scriptText = document.createTextNode(oldScript.innerHTML)
      newScript.appendChild(scriptText)
      oldScript.parentNode.replaceChild(newScript, oldScript)
    })

    // Append snippet to page
    document.body.appendChild(div)

    // Append a callback function for paygistix to utilize
    const script = document.createElement("script")
    script.innerHTML = getCallback()
    document.head.append(script)
    window.scrollTo(0, 0) // Ensure the top of the snippet is visible

    // Edit pay text on sale to look more a-peel-ing
    // You get it...
    // Like an banana or something...
    // Just imagine a customer is buying an banana...
    // Then this would be so funny :D
    if (title === "Sale") {
      const submitButton = document.getElementById("pgxSubmit")
      const amount = submitButton.innerHTML.split(" ")[1]
      submitButton.innerHTML = `Pay ${currency(amount).format()}`
    }
  }

  // Add any additional callback function strings here...
  function getCallback() {
    return `
      function PaygistixCallback(data) {
        // Check if callback has already been used
        const snippet = document.getElementById("snippet")
        if (snippet.exhausted) return console.log("REPEAT CALLBACK!")

        const result = JSON.parse(data)

        // If transaction has a result...
        if (result) {
          // Create a transaction log
          fetch(\`/api/add-document/TransactionLog\`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(result)
          })

          // If transaction was successful, perform the necessary operations
          // Invoices - Update invoice data
          // Voids - Triggers a check for voiding giftcard balances, if any
          if (result.Status === "0") {
            if (result.ServiceType === "SALE" || result.ServiceType === "REPEATSALE") {
              if (result.InvoiceNumber) {
                fetch(\`/api/update-invoice/\${result.InvoiceNumber}?amount=\${result.Amount}\`)
              }
            }

            if (result.ServiceType === "VOID") {
              fetch(\`/api/giftcard/void?PNRef=\${result.OriginalPNRef}\`)
              fetch(\`/api/ticket/void?PNRef=\${result.OriginalPNRef}\`)
            }

            document.getElementById("snippet").callback(result)
            document.getElementById("snippet").exhausted = true 
          }
        }
        
        // Remove payment modal
        document.getElementById("cancelbtn").remove()
        setTimeout(() => document.getElementById('snippet').remove(), 3000)
      }
    `
  }

  function calculateTransaction(amount, taxRate, isTaxExempt, cardAdminFeeRate, cardAdminFeeFlat, isLoyalty = false, discounts = [], discountValue = 0, discountType = "amount", tipValue = 0, tipType = "amount", shippingValue = 0, shippingType = "amount") {
    // Get subtotal
    let subtotal = currency(amount)

    // Calculate discount
    let discount = currency(0)
    if (discountType === "amount") discount = currency(discountValue)
    if (discountType === "percent") discount = subtotal.multiply(discountValue / 100)

    for (const attachedDiscount of discounts) {
      if (attachedDiscount.isAmountOff)
        discount = discount.add(currency(attachedDiscount.amount, { fromCents: true }))

      if (attachedDiscount.isPercentOff)
        discount = discount.add(subtotal.multiply(attachedDiscount.percent / 100))

      if (attachedDiscount.isFixedPrice) {
        const a = subtotal.intValue
        const b = attachedDiscount.fixedPrice
        if (a > b) {
          subtotal = currency(Math.min(subtotal.intValue, attachedDiscount.fixedPrice), { fromCents: true })
        }
      }
    }

    // Ensure discount does not exceed the subtotal
    if (discount.intValue > subtotal.intValue) discount = subtotal

    // Calculate tax
    let tax = currency(0)
    if (!isTaxExempt) tax = subtotal.subtract(discount).multiply(taxRate / 100)

    // Store the cash total
    let cashTotal = subtotal.add(tax).subtract(discount)

    // Calculate card admin fee
    let cardAdminFee = currency(0)
    if (!isLoyalty) cardAdminFee = cashTotal.multiply(cardAdminFeeRate / 100).add(cardAdminFeeFlat)

    // Calculate card admin fee tax
    let cardAdminFeeTax = currency(0)
    if (!isTaxExempt) cardAdminFeeTax = cardAdminFee.multiply(taxRate / 100)

    // Calculate shipping amount
    let shipping = currency(0)
    if (shippingType === "amount") shipping = currency(shippingValue)
    if (shippingType === "percent") shipping = subtotal.multiply(shippingValue / 100)

    // Calculate tip
    let tip = currency(0)
    if (tipType === "amount") tip = currency(tipValue)
    if (tipType === "percent") tip = subtotal.subtract(discount).multiply(tipValue / 100)

    // Apply shipping and tip (This was delayed due to necessary CAF calculations)
    cashTotal = cashTotal.add(shipping).add(tip)

    // Calculate credit total
    const total = cashTotal.add(cardAdminFee).add(cardAdminFeeTax)

    return {
      subtotal: subtotal,
      shipping: shipping,
      discount: discount,
      tax: tax,
      tip: tip,
      total: total,
      cashTotal: cashTotal,
      cardAdminFee: cardAdminFee,
      cardAdminFeeTax: cardAdminFeeTax
    }
  }

  function calculateItemizedTransaction(lineItems, isTaxExempt, isLoyalty, discounts, discountValue, discountType, tipValue, tipType, shippingValue, shippingType) {
    // Subtotal phase
    let subtotal = currency(0), cashSubtotal = currency(0)
    let discount = currency(0), cashDiscount = currency(0)
    for (const lineItem of lineItems) {
      // Get line item unit price
      let unitPrice = currency(lineItem.price * lineItem.quantity, { fromCents: true })
      let unitCashPrice = currency(lineItem.cashPrice * lineItem.quantity, { fromCents: true })
      let cardAdminFeeRate = unitPrice.intValue / unitCashPrice.intValue
      if (Number.isFinite(cardAdminFeeRate) === false) cardAdminFeeRate = 0

      // Line item discount phase
      if (lineItem.isDiscountable && lineItem.discount !== null) {
        let itemCashDiscount = currency(0), itemDiscount = currency(0)

        if (lineItem.discount.isAmountOff) {
          // Calculate discount amount
          itemCashDiscount = currency(lineItem.discount.amount, { fromCents: true })
          itemDiscount = currency(lineItem.discount.amount, { fromCents: true }).multiply(cardAdminFeeRate)
        }

        if (lineItem.discount.isPercentOff) {
          // Calculate discount percentage
          itemCashDiscount = unitCashPrice.multiply(lineItem.discount.percent / 100)
          itemDiscount = unitPrice.multiply(lineItem.discount.percent / 100)
        }

        if (lineItem.discount.isFixedPrice) {
          // Calculate discount amount when shifting to target
          itemCashDiscount = currency(Math.max(unitCashPrice.intValue - lineItem.discount.fixedPrice, 0), { fromCents: true })
          itemDiscount = itemCashDiscount.multiply(cardAdminFeeRate)
        }

        // Update discount tracker
        cashDiscount = cashDiscount.add(itemCashDiscount)
        discount = discount.add(itemDiscount)
      }

      // Update ticket subtotal
      subtotal = subtotal.add(unitPrice)
      cashSubtotal = cashSubtotal.add(unitCashPrice)
    }

    // Derive the card admin fee rate for approximating card prices when neccessary
    let cardAdminFeeRate = subtotal.intValue / cashSubtotal.intValue
    if (Number.isFinite(cardAdminFeeRate) === false) cardAdminFeeRate = 0
    let priceHasBeenFixed = false, fixedTaxRate = 0

    // Ticket discount phase
    if (discountType === "amount") {
      cashDiscount = cashDiscount.add(discountValue)
      discount = discount.add(discountValue * cardAdminFeeRate)
    }

    if (discountType === "percent") {
      const amount = cashSubtotal.multiply(discountValue / 100)
      cashDiscount = cashDiscount.add(amount)
      discount = amount.multiply(cardAdminFeeRate)
    }

    for (const ticketDiscount of discounts) {
      let discountCashAmount = currency(0), discountAmount = currency(0)

      if (ticketDiscount.isAmountOff) {
        // Calculate discount amount
        discountCashAmount = currency(ticketDiscount.amount, { fromCents: true })
        discountAmount = currency(ticketDiscount.amount, { fromCents: true }).multiply(cardAdminFeeRate)
      }

      if (ticketDiscount.isPercentOff) {
        // Calculate discount percentage
        discountCashAmount = cashSubtotal.multiply(ticketDiscount.percent / 100)
        discountAmount = subtotal.multiply(ticketDiscount.percent / 100)
      }

      if (ticketDiscount.isFixedPrice) {
        if (cashSubtotal.intValue > ticketDiscount.fixedPrice) {
          // Calculate discount amount when shifting to target
          discountCashAmount = currency(cashSubtotal.intValue - ticketDiscount.fixedPrice, { fromCents: true })
          discountAmount = discountCashAmount.multiply(cardAdminFeeRate)
          priceHasBeenFixed = true
          fixedTaxRate = ticketDiscount.taxRate
        }
      }

      // Update discount tracker
      cashDiscount = cashDiscount.add(discountCashAmount)
      discount = discount.add(discountAmount)
    }

    // Ensure discount does not exceed subtotal
    if (discount.intValue > subtotal.intValue) discount = currency(subtotal)
    if (cashDiscount.intValue > cashSubtotal.intValue) cashDiscount = currency(cashSubtotal)

    // Tax phase
    let tax = currency(0), cashTax = currency(0)
    let cardAdminFee = currency(0), cardAdminFeeTax = currency(0)
    const discountedSubtotal = subtotal.subtract(discount)
    const discountedCashSubtotal = cashSubtotal.subtract(cashDiscount)

    if (priceHasBeenFixed) {
      tax = discountedSubtotal.multiply(fixedTaxRate / 100)
      cashTax = discountedCashSubtotal.multiply(fixedTaxRate / 100)
      cardAdminFee = discountedSubtotal.subtract(discountedCashSubtotal)
      cardAdminFeeTax = cardAdminFee.multiply(fixedTaxRate / 100)
    }

    for (const lineItem of lineItems) {
      // Get line item unit price
      let unitPrice = currency(lineItem.price * lineItem.quantity, { fromCents: true })
      let unitCashPrice = currency(lineItem.cashPrice * lineItem.quantity, { fromCents: true })

      if (!priceHasBeenFixed) {
        let discountContribution = (unitPrice.intValue / subtotal.intValue)
        let cashDiscountContribution = (unitCashPrice.intValue / cashSubtotal.intValue)

        let discountAmount = currency(discount.intValue * discountContribution, { fromCents: true })
        let discountCashAmount = currency(cashDiscount.intValue * cashDiscountContribution, { fromCents: true })

        unitPrice = unitPrice.subtract(discountAmount)
        unitCashPrice = unitCashPrice.subtract(discountCashAmount)
      }

      // Calculate and update card admin fee tracker
      const unitCardAdminFee = unitPrice.subtract(unitCashPrice)
      if (!priceHasBeenFixed) { cardAdminFee = cardAdminFee.add(unitCardAdminFee) }

      // Apply taxes, if necessary
      if (!lineItem.isTaxable || isTaxExempt || priceHasBeenFixed) continue
      tax = tax.add(currency(unitPrice, { fromCents: true }).multiply(lineItem.taxRate / 100))
      cashTax = cashTax.add(currency(unitCashPrice, { fromCents: true }).multiply(lineItem.taxRate / 100))
      cardAdminFeeTax = cardAdminFeeTax.add(unitCardAdminFee).multiply(lineItem.taxRate / 100)
    }

    // Calculate shipping
    let shipping = currency(0)
    if (shippingType === "amount") shipping = currency(shippingValue)
    if (shippingType === "percent") shipping = discountedCashSubtotal.multiply(shippingValue / 100)

    // Calculate tip
    let tip = currency(0)
    if (tipType === "amount") tip = currency(tipValue)
    if (tipType === "percent") tip = discountedCashSubtotal.multiply(tipValue / 100)

    // Totaling phase
    let total = discountedSubtotal.add(tax).add(cardAdminFeeTax).add(shipping).add(tip)
    let cashTotal = discountedCashSubtotal.add(cashTax).add(shipping).add(tip)

    return {
      subtotal: subtotal,
      shipping: shipping,
      discount: discount,
      tax: cashTax,
      tip: tip,
      total: isLoyalty ? cashTotal : total,
      cashTotal: cashTotal,
      cardAdminFee: cardAdminFee,
      cardAdminFeeTax: cardAdminFeeTax
    }
  }

  function ReceiptPrompt({ mid, merchantName, address, companyPhone }) {
    // Hooks
    const phoneForm = useForm({ mode: "onBlur", defaultValues: { phone: "" } })
    const emailForm = useForm({ mode: "onBlur", defaultValues: { email: "" } })

    // States
    const [textOpen, setTextOpen] = React.useState(false)
    const [emailOpen, setEmailOpen] = React.useState(false)

    return (
      <>
        <Dialog open={open} fullWidth maxWidth="md">
          <DialogTitle align="center" display="block">
            <Typography fontSize={24} fontWeight="bold">Would You Like a Receipt?</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{ display: "block", height: "100%", padding: 2 }}
                  onClick={() => setTextOpen(true)}
                >
                  <Typography fontSize={32}>Text</Typography>
                  <PhoneIphone sx={{ fontSize: 64 }} />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{ display: "block", height: "100%", padding: 2 }}
                  onClick={() => setEmailOpen(true)}
                >
                  <Typography fontSize={32}>Email</Typography>
                  <Email sx={{ fontSize: 64 }} />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{ display: "block", height: "100%", padding: 2 }}
                  onClick={() => onClick({
                    phone: "",
                    email: "",
                    merchantName: merchantName,
                    address: address,
                    companyPhone: companyPhone
                  }, true)}
                >
                  <Typography fontSize={32}>Print</Typography>
                  <Print sx={{ fontSize: 64 }} />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{ display: "block", height: "100%", padding: 2 }}
                  onClick={() => onClick()}
                >
                  <Typography fontSize={32}>No Receipt</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={textOpen}>
          <DialogTitle variant="h5" align="center" fontWeight="bold">
            Text Receipt
          </DialogTitle>
          <DialogContent>
            <Controller
              control={phoneForm.control}
              name="phone"
              rules={{
                required: "Phone number is required.",
                pattern: { value: PhoneRegex, message: "Please enter a valid phone number (xxx-xxx-xxxx)." },
              }}
              render={({ field, formState }) => (
                <PatternFormat
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  format="###-###-####"
                  customInput={TextField}
                  label="Phone Number"
                  helperText={get(formState.errors, "phone.message")}
                  error={Boolean(formState.errors.phone)}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={() => setTextOpen(false)}
            >
              Back
            </Button>
            <Button
              color="success"
              onClick={phoneForm.handleSubmit(() => {
                // setTextOpen(false)
                onClick({
                  phone: phoneForm.getValues("phone"),
                  email: "",
                  merchantName: merchantName,
                  address: address,
                  companyPhone: companyPhone
                })
              })}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={emailOpen}>
          <DialogTitle variant="h5" align="center" fontWeight="bold">
            Email Receipt
          </DialogTitle>
          <DialogContent>
            <TextField
              {...emailForm.register("email", {
                required: "Email address is required.",
                pattern: { value: EmailRegex, message: "Please enter a valid email address." },
              })}
              label="Email Address"
              helperText={get(emailForm.formState.errors, "email.message")}
              error={Boolean(emailForm.formState.errors.email)}
              onFocus={event => event.target.select()}
              onLoad={event => this.target.select()}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={() => setEmailOpen(false)}
            >
              Back
            </Button>
            <Button
              color="success"
              onClick={emailForm.handleSubmit(() => {
                // setEmailOpen(false)
                onClick({
                  phone: "",
                  email: emailForm.getValues("email"),
                  merchantName: merchantName,
                  address: address,
                  companyPhone: companyPhone
                })
              })}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )

  }

  async function promptReceipt(cash, credit, tax, cardAdminFee, cardAdminFeeTax, tip, invoiceNumber,  cardNumber, cardType, approvalCode) {
    setOpen(true)
    setTransaction({
      cash: cash,
      credit: credit,
      tax: tax,
      cardAdminFee: cardAdminFee,
      cardAdminFeeTax: cardAdminFeeTax,
      tip: tip,
      invoiceNumber: invoiceNumber,
      cardNumber: cardNumber,
      cardType: cardType,
      approvalCode: approvalCode
    })
    const [promise, resolve] = await usePromise()
    setResolver({ resolve })
    return promise
  }

  async function onClick(event = {}, print = false) {
    if (print) {
      fetch("/api/print-receipt/ticket", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: event.merchantName,
          city: event.address.city,
          state: event.address.state,
          zip: event.address.zipcode,
          phoneNumber: event.phone,
          address: `${event.address.line1} ${event.address.line2}`,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          mid: mid,
          deviceId: "",
          type: 0,
          total: transaction.credit,
          subtotal: transaction.credit - transaction.tax,
          tax: transaction.tax,
          change: 0,
          tendered: transaction.credit,
          invoiceId: transaction.invoiceNumber,
          companyPhone: event.companyPhone,
          payments: [],
          email: event.email,
          cardType: transaction.cardType,
          isCredit: true,
          accountNum: transaction.cardNumber,
          authCode: transaction.approvalCode
        })
      }).then(response => response.blob())
        .then(data => {
          const url = window.URL.createObjectURL(data)
          window.location.assign(url)
        })
    } else if (event.phone || event.email) {
      fetch("/api/receipts", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: event.merchantName,
          city: event.address.city,
          state: event.address.state,
          zip: event.address.zipcode,
          phoneNumber: event.phone,
          address: `${event.address.line1} ${event.address.line2}`,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          mid: mid,
          deviceId: "",
          type: 0,
          total: transaction.credit,
          subtotal: transaction.credit - transaction.tax,
          tax: transaction.tax,
          change: 0,
          tendered: transaction.credit,
          invoiceId: transaction.invoiceNumber,
          companyPhone: event.companyPhone,
          payments: [],
          email: event.email,
          cardType: transaction.cardType,
          isCredit: true,
          accountNum: transaction.cardNumber,
          authCode: transaction.approvalCode
        })
      }).then(response => {
        if (response.status === 200) enqueueSnackbar("Receipt sent!", { variant: "success" })
        else enqueueSnackbar("Something went wrong.", { variant: "error" })
      })
    }
    setOpen(false)
    resolver.resolve()
  }

  return {
    receipt: [promptReceipt, ReceiptPrompt],
    calculateTransaction: calculateTransaction,
    calculateItemizedTransaction: calculateItemizedTransaction,
    sale: sale,
    repeatSale: repeatSale,
    refund: refund,
    reverse: reverse,
    adjustTip: adjustTip,
    sendInvoiceViaEmail: sendInvoiceViaEmail,
    sendInvoiceViaText: sendInvoiceViaText,
    getTransactions: getTransactions,
    closeBatch: closeBatch
  }
}